:root {
  --white: #ffffff;
  --black: #333333;
  --logoColor: #168DA1;
  --greyText: #5C6574;
  --lightGrey: #D9D9D9;
  --greyBackground: #F3F4F4;
}

h1 {
  font-family: "David Libre", serif;
  font-weight: 700;
  font-size: 75px;
  margin-bottom: 0px;
}

div {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
}